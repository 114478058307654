import NumberFormat, { NumberFormatValues } from "react-number-format";

import { convertToUSD } from "helpers/formatting";
import { NumericText, Button } from "components";

import styles from "./NumericTextField.module.scss";

type NumericTextFieldProps = {
  value: number | string | null;
  onChange: (value: string | number) => void;
  maxValue: string;
  tokenPrice: number;
  symbol: string;
  tokenSymbol: string;
};

const NumericTextField = ({
  value,
  onChange,
  maxValue,
  tokenPrice,
  symbol,
  tokenSymbol,
}: NumericTextFieldProps) => {
  const onValueChange = (values: NumberFormatValues) => {
    onChange(Number(values.value));
  };

  const handleMaxClick = () => {
    onChange(maxValue);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.tokenWrapper}>
        <p className={styles.poolToken}>Pool Token</p>

        <div className={styles.avatarWrapper}>
          <span className={styles.poolAbbr}>
            {symbol} - {tokenSymbol}
          </span>
        </div>
      </div>

      <div className={styles.inputWrapper}>
        <span className={styles.price}>
          ≈ <NumericText value={convertToUSD(value, tokenPrice)} prefix="$" />
        </span>

        <Button onClick={handleMaxClick} className={styles.maxButton}>
          MAX
        </Button>

        <NumberFormat
          value={value}
          onValueChange={onValueChange}
          placeholder="0"
          thousandSeparator
          isNumericString
          allowNegative={false}
          allowLeadingZeros={false}
          className={styles.input}
        />
      </div>
    </div>
  );
};

export default NumericTextField;
