import { useState } from "react";

import { Row, Col } from "antd";

import { Pool } from "types";
import ethereum from "utils/ethereum";
import { usePoolMutations } from "hooks/mutations";
import { useModal } from "hooks";
import {
  Button,
  NumericText,
  Modal,
  LoadingModal,
  NumericTextField,
  EarningsGrid,
} from "components";

import styles from "./WithdrawModal.module.scss";

type WithdrawModalProps = {
  pool: Pool;
};

const WithdrawModal = ({
  pool: {
    balance,
    stake,
    liquidityTokenAddress,
    poolIndex,
    tokenPrice,
    symbol,
    tokenSymbol,
  },
}: WithdrawModalProps) => {
  const { withdraw, transactionLink } = usePoolMutations(
    liquidityTokenAddress,
    poolIndex
  );
  const { isOpen, onOpen, onClose } = useModal();
  const [inputValue, setInputValue] = useState<number | string>(0);

  const maxValue = stake;
  const isDisabledButton = !inputValue || inputValue > Number(maxValue);

  const earnings = ethereum.calculateEarnings(inputValue, "-");

  const resetInputValue = () => setInputValue(0);

  const handleWithdraw = async () => {
    try {
      onOpen();
      await withdraw(inputValue);
    } catch (error) {
      console.log(error);
    } finally {
      resetInputValue();
      onClose();
    }
  };

  return (
    <div className={styles.depositWrapper}>
      <h4 className={styles.title}>Unstake</h4>

      <Row gutter={[0, 10]}>
        <Col span={24} className={styles.amountWrapper}>
          <span>Amount</span>
          <span className={styles.balance}>
            Staked Amount: <NumericText value={stake} precision={3} />
          </span>
        </Col>

        <Col span={24}>
          <NumericTextField
            value={inputValue}
            onChange={setInputValue}
            maxValue={maxValue}
            tokenPrice={tokenPrice}
            symbol={symbol}
            tokenSymbol={tokenSymbol}
          />
        </Col>
      </Row>

      <EarningsGrid
        title="Your Balance"
        value={balance}
        earnings={earnings}
        tokenPrice={tokenPrice}
        tokenSymbol={tokenSymbol}
      />

      <Row gutter={[0, 10]}>
        <Col span={24}>
          <Button
            className={styles.button}
            onClick={handleWithdraw}
            disabled={isDisabledButton}
          >
            Unstake
          </Button>
        </Col>
      </Row>

      <Modal visible={isOpen} onCancel={onClose} centered>
        <LoadingModal onClose={onClose} transactionLink={transactionLink} />
      </Modal>
    </div>
  );
};

export default WithdrawModal;
