export const ERC20TokenABI = [
  "function symbol() public view returns (string)",
  "function decimals() public view returns (uint8)",
  "function balanceOf(address _owner) public view returns (uint256 balance)",
  "function allowance(address _owner, address _spender) public view returns (uint256 remaining)",
  "function approve(address _spender, uint256 _value) public returns (bool success)",
];

export const FarmingABI = [
  "function getPool(uint16 _index) external view returns (address, uint256, uint64, uint64, uint64, uint256, uint256)",
  "function getContractData() external view returns (uint256, uint256, uint64)",
  "function getUserInfo(uint16 _pid, address _user) external view returns (uint256, uint256, uint256)",
  "function deposit(uint16 _pid, uint256 _amount) public",
  "function withdraw(uint16 _pid, uint256 _amount) public",
  "function claimReward(uint16 _pid) public",
  "function exit(uint16 _pid) public",
  "function rewardToken() public view returns(address)",
  "function claimAndStake(uint16 _pid) public",
];
