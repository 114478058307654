import clsx from "clsx";
import { Col, Row } from "antd";

import { Earnings } from "types";
import { convertToUSD } from "helpers/formatting";
import { NumericText } from "components";

import styles from "./EarningsGrid.module.scss";

type EarningsGridProps = {
  title: string;
  value: string | number;
  earnings: Earnings;
  tokenPrice: number;
  tokenSymbol: string;
};

const EarningsGrid = ({
  title,
  value,
  earnings: {
    dailyEarning,
    monthlyEarning,
    yearlyEarning,
    dailyROI,
    monthlyROI,
    yearlyROI,
  },
  tokenPrice,
  tokenSymbol,
}: EarningsGridProps) => (
  <Row gutter={[0, 10]} className={styles.grid}>
    <Col span={24}>
      <span className={clsx(styles.title, styles.gridTitle)}>{title}</span>
      <span className={styles.value}>
        <NumericText value={value} />
      </span>
    </Col>

    <Col span={24}>
      <span className={styles.gridTitle}>Daily Earnings</span>
      <NumericText
        value={dailyEarning}
        suffix={tokenSymbol}
        className={styles.earningMainCurrency}
      />
      <NumericText
        value={convertToUSD(dailyEarning, tokenPrice)}
        prefix="$"
        className={styles.earningConverted}
      />
      <NumericText
        value={dailyROI}
        prefix="ROI "
        suffix="%"
        fixedDecimalScale
        className={styles.earningConverted}
      />
    </Col>

    <Col span={24}>
      <span className={styles.gridTitle}>Monthly Earnings</span>
      <NumericText
        value={monthlyEarning}
        suffix={tokenSymbol}
        className={styles.earningMainCurrency}
      />
      <NumericText
        value={convertToUSD(monthlyEarning, tokenPrice)}
        prefix="$"
        className={styles.earningConverted}
      />
      <NumericText
        value={monthlyROI}
        prefix="ROI "
        suffix="%"
        fixedDecimalScale
        className={styles.earningConverted}
      />
    </Col>

    <Col span={24}>
      <span className={styles.gridTitle}>Yearly Earnings</span>
      <NumericText
        value={yearlyEarning}
        suffix={tokenSymbol}
        className={styles.earningMainCurrency}
      />
      <NumericText
        value={convertToUSD(yearlyEarning, tokenPrice)}
        prefix="$"
        className={styles.earningConverted}
      />
      <NumericText
        value={yearlyROI}
        prefix="ROI "
        suffix="%"
        fixedDecimalScale
        className={styles.earningConverted}
      />
    </Col>
  </Row>
);

export default EarningsGrid;
