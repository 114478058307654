import { Avatar, Layout } from "antd";

import { WalletType } from "types";
import { NETWORKS } from "config";
import { useModal, useWallet } from "hooks";
import { getShortWalletAddress } from "helpers/formatting";
import {
  Alert,
  Button,
  Modal,
  NumericText,
  WalletsModal,
  DisconnectWalletModal,
} from "components";

import { ReactComponent as Logo } from "assets/company-logo.svg";
import metamaskLogo from "assets/metamask.svg";
import walletConnectLogo from "assets/wallet-connect.svg";

import { NetworksDropdown } from "./components";

import styles from "./Header.module.scss";

const { Header: AntdHeader } = Layout;

const Header = () => {
  const {
    connected,
    account,
    balance,
    connecting,
    networkId,
    walletType,
    activeNetwork,
    onNetworkChange,
  } = useWallet();
  const { isOpen, onOpen, onClose } = useModal();
  const {
    isOpen: isOpenDisconnect,
    onOpen: handleOpenDisconnect,
    onClose: handleCloseDisconnect,
  } = useModal();

  const handleConnectWalletClick = () => {
    onOpen();
  };

  const renderAccount = () => {
    if (connecting) {
      return null;
    }

    if (connected) {
      let avatar;

      if (walletType === WalletType.metamask) {
        avatar = metamaskLogo;
      }
      if (walletType === WalletType.walletConnect) {
        avatar = walletConnectLogo;
      }

      return (
        <button
          type="button"
          className={styles.account}
          onClick={handleOpenDisconnect}
        >
          <Avatar src={avatar} className={styles.accountLogo} />

          <span className={styles.balance}>
            <NumericText
              value={balance}
              precision={4}
              suffix={activeNetwork.symbol}
            />
          </span>

          <span className={styles.walletAddress}>
            {getShortWalletAddress(account)}
          </span>
        </button>
      );
    }

    return (
      <Button
        onClick={handleConnectWalletClick}
        className={styles.connectButton}
        data-testid="connect-button"
      >
        Connect Wallet
      </Button>
    );
  };

  const renderNetworkAlert = () => {
    if (!networkId || !account) {
      return null;
    }

    if (networkId !== activeNetwork.networkId) {
      return (
        <Alert type="error">
          App network ({activeNetwork.networkId}) does not match to network
          selected in wallet: {networkId}. Learn how to change network in
          wallet.
        </Alert>
      );
    }

    return null;
  };

  return (
    <>
      <AntdHeader className={styles.header}>
        <Logo />

        <div className={styles.actionsWrapper}>
          <NetworksDropdown
            menu={NETWORKS}
            value={activeNetwork}
            onChange={onNetworkChange}
          />

          <div className={styles.accountWrapper}>{renderAccount()}</div>
        </div>
      </AntdHeader>

      <Modal visible={isOpen} onCancel={onClose} width={400}>
        <WalletsModal onClose={onClose} />
      </Modal>

      <Modal
        visible={isOpenDisconnect}
        onCancel={handleCloseDisconnect}
        width={460}
      >
        <DisconnectWalletModal
          network={activeNetwork}
          onClose={handleCloseDisconnect}
        />
      </Modal>

      {renderNetworkAlert()}
    </>
  );
};

export default Header;
