import { Dictionary } from "lodash";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { NetworkType } from "types";

import ethereumLogo from "assets/ethereum-eth-logo.svg";
import binanceLogo from "assets/binance-coin-bnb-logo.svg";
import ethereumContainedLogo from "assets/ethereum.svg";
import binanceContainedLogo from "assets/bsc.svg";

dayjs.extend(utc);

const AMOUNT_OF_BLOCKERS_PER_YEAR_ETHEREUM: number = 2254114;
const AMOUNT_OF_BLOCKERS_PER_YEAR_BSC: number = 10500000;

export const RINKEBY_NETWORK: NetworkType = {
  name: "Rinkeby",
  networkId: 4,
  avatar: ethereumLogo,
  avatarContained: ethereumContainedLogo,
  address: process.env.REACT_APP_RINKEBY_FARMING || "",
  amountOfBlockersPerYear: AMOUNT_OF_BLOCKERS_PER_YEAR_ETHEREUM,
  symbol: "ETH",
};

export const BSC_TEST_NETWORK: NetworkType = {
  name: "BSC Test",
  networkId: 97,
  avatar: binanceLogo,
  avatarContained: binanceContainedLogo,
  address: process.env.REACT_APP_BSC_TEST_FARMING || "",
  amountOfBlockersPerYear: AMOUNT_OF_BLOCKERS_PER_YEAR_BSC,
  symbol: "BNB",
};

export const ETHEREUM_MAINNET_NETWORK: NetworkType = {
  name: "Ethereum",
  networkId: 1,
  avatar: ethereumLogo,
  avatarContained: ethereumContainedLogo,
  address: process.env.REACT_APP_MAINNET_FARMING || "",
  amountOfBlockersPerYear: AMOUNT_OF_BLOCKERS_PER_YEAR_ETHEREUM,
  symbol: "ETH",
};

export const BSC_MAINNET_NETWORK: NetworkType = {
  name: "BSC Mainnet",
  networkId: 56,
  avatar: binanceLogo,
  avatarContained: binanceContainedLogo,
  address: process.env.REACT_APP_BSC_MAINNET_FARMING || "",
  amountOfBlockersPerYear: AMOUNT_OF_BLOCKERS_PER_YEAR_BSC,
  symbol: "BNB",
};

export const NETWORKS: NetworkType[] = [
  // Rinkeby and BSC Test only for testing
  // RINKEBY_NETWORK,
  // BSC_TEST_NETWORK,
  ETHEREUM_MAINNET_NETWORK,
  // BSC_MAINNET_NETWORK,
];

export const NETWORKS_ID: Dictionary<NetworkType> = {
  [RINKEBY_NETWORK.networkId]: RINKEBY_NETWORK,
  [BSC_TEST_NETWORK.networkId]: BSC_TEST_NETWORK,
  [ETHEREUM_MAINNET_NETWORK.networkId]: ETHEREUM_MAINNET_NETWORK,
  [BSC_MAINNET_NETWORK.networkId]: BSC_MAINNET_NETWORK,
};

export const DEFAULT_NETWORK: NetworkType = NETWORKS[0];

// Pool
// TODO: Need to update when will be add new pools
export const POOL_START_DATE = 1638489600000;
export const POOL_END_DATE = 1730678400000;

export const SHOW_UNSTAKE_AND_EXIT_BUTTONS = true;

// Wallet Connect
const INFURA_API_KEY = process.env.REACT_APP_INFURA_API_KEY;

export const WALLET_CONNECT_CONFIG = {
  infuraId: INFURA_API_KEY,
  rpc: {
    [ETHEREUM_MAINNET_NETWORK.networkId]: `https://mainnet.infura.io/v3/${INFURA_API_KEY}`,
    [RINKEBY_NETWORK.networkId]: `https://rinkeby.infura.io/v3/${INFURA_API_KEY}`,
    [BSC_MAINNET_NETWORK.networkId]: "https://bsc-dataseed1.binance.org",
    [BSC_TEST_NETWORK.networkId]:
      "https://data-seed-prebsc-1-s1.binance.org:8545",
  },
};
