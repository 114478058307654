import { useCallback, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import {
  openNotification,
  openErrorNotification,
} from "components/Notification";

import ethereum from "utils/ethereum";

const useCoinMutations = (address: string, pid: number) => {
  const queryClient = useQueryClient();
  const [transactionLink, setTransactionLink] = useState<string>("");

  const refetchQuery = useCallback(() => {
    queryClient.invalidateQueries("pool");
  }, [queryClient]);

  const handleError = useCallback((error) => {
    if (error.message === "userRejectedRequest") {
      openErrorNotification("Transaction has been canceled");
    } else {
      openErrorNotification(`Transaction rejected: ${error.message}`);
    }
  }, []);

  const handleSuccess = useCallback(
    (url: string, message: string) => {
      refetchQuery();
      openNotification(message, url);
    },
    [refetchQuery]
  );

  const handleTransactionLink = useCallback(async (mutationFn) => {
    setTransactionLink("");
    const transactionResponse = await mutationFn;
    const link = ethereum.getExplorerLink(transactionResponse.hash);
    setTransactionLink(link);

    await transactionResponse.wait();
    return link;
  }, []);

  const approve = useMutation(
    async () => handleTransactionLink(ethereum.approve(address)),
    {
      onSuccess: (url: string) => handleSuccess(url, "Approve success"),
      onError: handleError,
    }
  );

  const deposit = useMutation(
    async (value: number | string) =>
      handleTransactionLink(ethereum.deposit(value, address, pid)),
    {
      onSuccess: (url: string) => handleSuccess(url, "Stake success"),
      onError: handleError,
    }
  );

  const withdraw = useMutation(
    async (value: number | string) =>
      handleTransactionLink(ethereum.withdraw(value, address, pid)),
    {
      onSuccess: (url: string) => handleSuccess(url, "Unstake success"),
      onError: handleError,
    }
  );

  const claim = useMutation(
    async () => handleTransactionLink(ethereum.claim(pid)),
    {
      onSuccess: (url: string) => handleSuccess(url, "Claim success"),
      onError: handleError,
    }
  );

  const exit = useMutation(
    async () => handleTransactionLink(ethereum.exit(pid)),
    {
      onSuccess: (url: string) => handleSuccess(url, "Exit success"),
      onError: handleError,
    }
  );

  const claimAndStake = useMutation(
    async () => handleTransactionLink(ethereum.claimAndStake(pid)),
    {
      onSuccess: (url: string) => handleSuccess(url, "Claim and stake success"),
      onError: handleError,
    }
  );

  return {
    isLoading:
      approve.isLoading ||
      deposit.isLoading ||
      withdraw.isLoading ||
      claim.isLoading ||
      exit.isLoading ||
      claimAndStake.isLoading,
    approve: approve.mutateAsync,
    deposit: deposit.mutateAsync,
    withdraw: withdraw.mutateAsync,
    claim: claim.mutateAsync,
    exit: exit.mutateAsync,
    claimAndStake: claimAndStake.mutateAsync,
    transactionLink,
  };
};

export default useCoinMutations;
