import { useQuery } from "react-query";

import { Pool } from "types";
import ethereum from "utils/ethereum";

const CONTRACT_ADDRESS =
  process.env.REACT_APP_BINANCE_SMART_CHAIN_CONTRACT_ADDRESS || "";
const CURRENCY = "usd";
const TOKEN_PRICE_API_URL = `https://api.coingecko.com/api/v3/simple/token_price/binance-smart-chain?contract_addresses=${CONTRACT_ADDRESS}&vs_currencies=${CURRENCY}`;

const usePool = () =>
  useQuery<Pool | null>("pool", async () => {
    const pool = await ethereum.getPoolData();

    const response = await fetch(TOKEN_PRICE_API_URL);
    const data = await response.json();

    pool.tokenPrice = data[CONTRACT_ADDRESS][CURRENCY];

    return pool;
  });

export default usePool;
