export const getShortWalletAddress = (address: string) =>
  `${address.slice(0, 6)}...${address.slice(-4)}`;

export const convertToUSD = (
  amount: number | string | null,
  tokenPrice: number
) => {
  if (!amount) {
    return amount;
  }

  return Number(amount) * tokenPrice;
};
