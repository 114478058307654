import { createContext } from "react";
import { WalletStatus, WalletType, NetworkType } from "types";
import { DEFAULT_NETWORK } from "config";

type WalletContextProps = {
  account: string;
  connect: (type: WalletType) => void;
  disconnect: () => void;
  status: WalletStatus;
  balance: string;
  networkId: number | null;
  walletType: WalletType | string;
  activeNetwork: NetworkType;
  onNetworkChange: (network: NetworkType) => void;
};

const WalletContext = createContext<WalletContextProps>({
  account: "",
  connect() {},
  disconnect() {},
  status: WalletStatus.connecting,
  balance: "",
  networkId: null,
  walletType: "",
  activeNetwork: DEFAULT_NETWORK,
  onNetworkChange() {},
});

export default WalletContext;
