import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const DEFAULT_FORMAT = "DD MMM YYYY HH:mm";

export const formatUTC = (
  timestamp: number | string,
  format: string = DEFAULT_FORMAT
): string => dayjs.utc(Number(timestamp)).format(format);
