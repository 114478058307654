import { BigNumber } from "ethers";

export type NetworkType = {
  name: string;
  networkId: number;
  avatar: string;
  avatarContained: string;
  address: string;
  amountOfBlockersPerYear: number;
  symbol: string;
};

export enum PoolState {
  pending = "pending",
  active = "active",
  ended = "ended",
}

export type Pool = {
  symbol: string;
  totalStaked: string;
  farmingAPY: number;
  startDate: number;
  endDate: number;
  stake: string;
  reward: string;
  state: PoolState;
  balance: string;
  liquidityTokenAddress: string;
  poolIndex: number;
  allowance: boolean;
  tokenPrice: number;
  tokenSymbol: string;
  amount: BigNumber;
  isZeroTotals: boolean;
  tokensFarmedPerBlock: BigNumber;
  allocPoint: BigNumber;
  totalAllocPoint: BigNumber;
  totalLiquidityDeposited: BigNumber;
  decimals: BigNumber;
};

export enum WalletStatus {
  connecting = "connecting",
  connected = "connected",
  disconnected = "disconnected",
}

export enum WalletType {
  metamask = "metamask",
  walletConnect = "walletConnect",
}

export type Earnings = {
  dailyEarning: number;
  monthlyEarning: number;
  yearlyEarning: number;
  dailyROI: number;
  monthlyROI: number;
  yearlyROI: number;
};
