import { Row, Col } from "antd";

import { usePool } from "hooks/query";
import { PoolCard, Loader } from "components";

const PoolsList = () => {
  const { isLoading, data } = usePool();

  if (isLoading) {
    return <Loader />;
  }

  if (!data) {
    return null;
  }

  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <PoolCard pool={data} />
      </Col>
    </Row>
  );
};

export default PoolsList;
