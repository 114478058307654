import { useState } from "react";

import { Row, Col } from "antd";

import { Pool } from "types";
import ethereum from "utils/ethereum";
import { usePoolMutations } from "hooks/mutations";
import { useModal } from "hooks";
import {
  Button,
  NumericText,
  Modal,
  LoadingModal,
  NumericTextField,
  EarningsGrid,
} from "components";

import styles from "./DepositModal.module.scss";

type DepositModalProps = {
  pool: Pool;
};

const DepositModal = ({
  pool: {
    symbol,
    balance,
    stake,
    liquidityTokenAddress,
    allowance,
    poolIndex,
    tokenPrice,
    tokenSymbol,
  },
}: DepositModalProps) => {
  const { approve, deposit, transactionLink } = usePoolMutations(
    liquidityTokenAddress,
    poolIndex
  );
  const { isOpen, onOpen, onClose } = useModal();
  const [inputValue, setInputValue] = useState<number | string>(0);

  const maxValue = balance;
  const isDisabledButton =
    !allowance || !inputValue || inputValue > Number(maxValue);

  const earnings = ethereum.calculateEarnings(inputValue, "+");

  const resetInputValue = () => setInputValue(0);

  const handleApprove = async () => {
    try {
      onOpen();
      await approve();
    } catch (error) {
      console.log(error);
    } finally {
      onClose();
    }
  };

  const handleDeposit = async () => {
    try {
      onOpen();
      await deposit(inputValue);
    } catch (error) {
      console.log(error);
    } finally {
      resetInputValue();
      onClose();
    }
  };

  return (
    <div className={styles.depositWrapper}>
      <h4 className={styles.title}>Stake</h4>

      <Row gutter={[0, 10]}>
        <Col span={24} className={styles.amountWrapper}>
          <span>Amount</span>
          <span className={styles.balance}>
            {tokenSymbol} Balance: <NumericText value={balance} precision={3} />
          </span>
        </Col>

        <Col span={24}>
          <NumericTextField
            value={inputValue}
            onChange={setInputValue}
            maxValue={maxValue}
            tokenPrice={tokenPrice}
            symbol={symbol}
            tokenSymbol={tokenSymbol}
          />
        </Col>
      </Row>

      <EarningsGrid
        title="Your Staked"
        value={stake}
        earnings={earnings}
        tokenPrice={tokenPrice}
        tokenSymbol={tokenSymbol}
      />

      <Row gutter={[0, { md: 10, sm: 20, xs: 20 }]}>
        <Col span={24}>
          <Button
            className={styles.button}
            onClick={handleApprove}
            disabled={allowance}
          >
            Approve {symbol}
          </Button>
        </Col>
        <Col span={24}>
          <Button
            className={styles.button}
            onClick={handleDeposit}
            disabled={isDisabledButton}
          >
            Stake
          </Button>
        </Col>
      </Row>

      <Modal visible={isOpen} onCancel={onClose} centered>
        <LoadingModal onClose={onClose} transactionLink={transactionLink} />
      </Modal>
    </div>
  );
};

export default DepositModal;
