import { QueryClient, QueryClientProvider } from "react-query";
import { WalletProvider } from "context";
import { Layout } from "components";
import { Farming } from "views";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <WalletProvider>
        <Layout>
          <Farming />
        </Layout>
      </WalletProvider>
    </QueryClientProvider>
  );
}

export default App;
