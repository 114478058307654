import { ReactNode } from "react";

import styles from "./DetailsText.module.scss";

type DetailsTextProps = {
  label: string;
  children: ReactNode;
};

const DetailsText = ({ children, label }: DetailsTextProps) => (
  <div className={styles.detailsText}>
    <p className={styles.label}>{label}</p>
    <p className={styles.text}>{children}</p>
  </div>
);

export default DetailsText;
