import { ethers, BigNumber } from "ethers";
import detectEthereumProvider from "@metamask/detect-provider";
import { createExplorerLink } from "@metamask/etherscan-link";
import { errorCodes, serializeError, EthereumRpcError } from "eth-rpc-errors";
import { formatFixed, parseFixed } from "@ethersproject/bignumber";
import { TransactionResponse } from "@ethersproject/abstract-provider";
import WalletConnectProvider from "@walletconnect/web3-provider";

import { NetworkType, Pool, PoolState, Earnings } from "types";
import {
  POOL_END_DATE,
  POOL_START_DATE,
  DEFAULT_NETWORK,
  BSC_MAINNET_NETWORK,
  BSC_TEST_NETWORK,
  NETWORKS_ID,
} from "config";

import { ERC20TokenABI, FarmingABI } from "./abi";

const UINT256_MAX_INT = BigNumber.from(
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
);

type Error = {
  originalError?: {
    reason: string;
    error?: {
      message: string;
    };
  };
};

class Ethereum {
  private metaMaskWallet = (window as any).ethereum;

  private provider =
    this.metaMaskWallet &&
    new ethers.providers.Web3Provider(this.metaMaskWallet);

  private account: string = "";

  private network: NetworkType = DEFAULT_NETWORK;

  // @ts-ignore
  private pool: Pool;

  init = (
    walletProvider: null | WalletConnectProvider,
    accountsChanged: (account: string) => void,
    networkChanged: (chainId: number) => void
  ): void => {
    const newProvider = walletProvider || this.metaMaskWallet;
    this.provider = new ethers.providers.Web3Provider(newProvider);

    newProvider.on("accountsChanged", ([account]: string[]) => {
      this.account = account || "";
      accountsChanged(this.account);
    });

    const provider = new ethers.providers.Web3Provider(newProvider, "any");
    provider.on("network", ({ chainId }) => {
      // Set current network SC address
      this.network = NETWORKS_ID[chainId];

      // Update provider when network changed
      this.provider = new ethers.providers.Web3Provider(newProvider);

      networkChanged(chainId);
    });
  };

  getMetaMaskWalletAccount = async (request: boolean): Promise<string> => {
    const method = request ? "eth_requestAccounts" : "eth_accounts";
    const [account] = await this.metaMaskWallet.request({
      method,
    });
    return account || "";
  };

  isMetaMaskProviderExist = async (): Promise<boolean> => {
    const provider = await detectEthereumProvider();
    return !!provider;
  };

  setWalletAccount = (account: string): void => {
    this.account = account;
  };

  setNetwork = (newNetwork: NetworkType): void => {
    this.network = newNetwork;
  };

  handleWalletError = (error: EthereumRpcError<Error> | unknown) => {
    const { code, data } = serializeError(error);
    const errorData = data as Error;

    const message =
      errorData?.originalError?.error?.message ||
      errorData?.originalError?.reason ||
      "Transaction error";
    const { userRejectedRequest } = errorCodes.provider;

    if (userRejectedRequest === code) {
      throw new Error("userRejectedRequest");
    } else {
      throw new Error(message);
    }
  };

  getBalance = async (): Promise<string> => {
    const balance = await this.provider.getBalance(this.account);
    return ethers.utils.formatEther(balance);
  };

  getNetwork = async (): Promise<number> => {
    const { chainId } = await this.provider.getNetwork();
    return chainId;
  };

  getBlockNumber = async (): Promise<number> => {
    const blockNumber = await this.provider.getBlockNumber();
    return blockNumber;
  };

  parseDecimals = async (
    value: number | string,
    address: string
  ): Promise<BigNumber> => {
    const contract = new ethers.Contract(address, ERC20TokenABI, this.provider);
    const decimals = await contract.decimals();
    return parseFixed(value.toString(), decimals);
  };

  isTokenAllowance = async (address: string): Promise<boolean> => {
    const signer = this.provider.getSigner();
    const contract = new ethers.Contract(address, ERC20TokenABI, signer);
    const allowance = await contract.allowance(
      this.account,
      this.network.address
    );

    return !BigNumber.from(allowance).isZero();
  };

  getPoolData = async (): Promise<Pool> => {
    const { amountOfBlockersPerYear } = this.network;

    const signer = this.provider.getSigner();
    const contract = new ethers.Contract(
      this.network.address,
      FarmingABI,
      signer
    );

    // IMPORTANT: please use _index = 0 at this time, we’ll have only one pool now.
    const poolIndex = 0;
    const pool = await contract.getPool(poolIndex);
    const liquidityTokenAddress = pool[0];
    const allocPoint = pool[1];
    const startBlock = pool[2].toNumber();
    const endBlock = pool[3].toNumber();
    const totalLiquidityDeposited = pool[6];

    const contractData = await contract.getContractData();
    const tokensFarmedPerBlock = contractData[0];
    const totalAllocPoint = contractData[1];

    const liquidityTokenContract = new ethers.Contract(
      liquidityTokenAddress,
      ERC20TokenABI,
      signer
    );
    const symbol: string = await liquidityTokenContract.symbol();
    const decimals: BigNumber = await liquidityTokenContract.decimals();

    const totalStaked: string = formatFixed(totalLiquidityDeposited, decimals);

    const isZeroTotals: boolean =
      totalAllocPoint.isZero() || totalLiquidityDeposited.isZero();
    const farmingAPY: number = isZeroTotals
      ? 0
      : BigNumber.from(100)
          .mul(BigNumber.from(amountOfBlockersPerYear))
          .mul(allocPoint)
          .div(totalAllocPoint)
          .mul(tokensFarmedPerBlock)
          .div(totalLiquidityDeposited)
          .toNumber();

    // User info
    const userInfo = await contract.getUserInfo(poolIndex, this.account);
    const amount = userInfo[0];
    // const totalRewarded = userInfo[1];
    const pendingReward = userInfo[2];
    const stake = formatFixed(amount, decimals);
    const reward = formatFixed(pendingReward, decimals);
    const balanceOfAccount = await liquidityTokenContract.balanceOf(
      this.account
    );
    const balance = formatFixed(balanceOfAccount, decimals);

    // State
    const currentBlock = await this.getBlockNumber();
    const getPoolState = (): PoolState => {
      if (currentBlock < startBlock) {
        return PoolState.pending;
      }

      if (currentBlock > endBlock) {
        return PoolState.ended;
      }

      return PoolState.active;
    };
    const state: PoolState = getPoolState();

    // Allowance
    const allowance = await this.isTokenAllowance(liquidityTokenAddress);

    // Reward token symbol
    const rewardToken = await contract.rewardToken();
    const tokenContract = new ethers.Contract(
      rewardToken,
      ERC20TokenABI,
      signer
    );
    const tokenSymbol = await tokenContract.symbol();

    const poolData: Pool = {
      symbol,
      totalStaked,
      farmingAPY,
      startDate: POOL_START_DATE,
      endDate: POOL_END_DATE,
      stake,
      reward,
      state,
      balance,
      liquidityTokenAddress,
      poolIndex,
      allowance,
      tokenSymbol,
      // Get from API
      tokenPrice: 0,
      amount,
      isZeroTotals,
      tokensFarmedPerBlock,
      allocPoint,
      totalAllocPoint,
      totalLiquidityDeposited,
      decimals,
    };

    this.pool = poolData;

    return poolData;
  };

  approve = async (
    liquidityTokenAddress: string
  ): Promise<TransactionResponse | unknown> => {
    try {
      const signer = this.provider.getSigner();
      const contract = new ethers.Contract(
        liquidityTokenAddress,
        ERC20TokenABI,
        signer
      );

      const approved: TransactionResponse = await contract.approve(
        this.network.address,
        UINT256_MAX_INT
      );
      return approved;
    } catch (error) {
      this.handleWalletError(error);
      return error;
    }
  };

  deposit = async (
    value: number | string,
    liquidityTokenAddress: string,
    poolIndex: number
  ): Promise<TransactionResponse | unknown> => {
    try {
      const signer = this.provider.getSigner();
      const contract = new ethers.Contract(
        this.network.address,
        FarmingABI,
        signer
      );

      const amount = await this.parseDecimals(value, liquidityTokenAddress);
      const createdDeposit: TransactionResponse = await contract.deposit(
        poolIndex,
        amount
      );
      return createdDeposit;
    } catch (error) {
      this.handleWalletError(error);
      return error;
    }
  };

  withdraw = async (
    value: number | string,
    liquidityTokenAddress: string,
    poolIndex: number
  ): Promise<TransactionResponse | unknown> => {
    try {
      const signer = this.provider.getSigner();
      const contract = new ethers.Contract(
        this.network.address,
        FarmingABI,
        signer
      );

      const amount = await this.parseDecimals(value, liquidityTokenAddress);
      const createdWithdraw: TransactionResponse = await contract.withdraw(
        poolIndex,
        amount
      );
      return createdWithdraw;
    } catch (error) {
      this.handleWalletError(error);
      return error;
    }
  };

  claim = async (poolIndex: number): Promise<TransactionResponse | unknown> => {
    try {
      const signer = this.provider.getSigner();
      const contract = new ethers.Contract(
        this.network.address,
        FarmingABI,
        signer
      );

      const createdClaim: TransactionResponse = await contract.claimReward(
        poolIndex
      );
      return createdClaim;
    } catch (error) {
      this.handleWalletError(error);
      return error;
    }
  };

  exit = async (poolIndex: number): Promise<TransactionResponse | unknown> => {
    try {
      const signer = this.provider.getSigner();
      const contract = new ethers.Contract(
        this.network.address,
        FarmingABI,
        signer
      );

      const createdExit = await contract.exit(poolIndex);
      return createdExit;
    } catch (error) {
      this.handleWalletError(error);
      return error;
    }
  };

  claimAndStake = async (
    poolIndex: number
  ): Promise<TransactionResponse | unknown> => {
    try {
      const signer = this.provider.getSigner();
      const contract = new ethers.Contract(
        this.network.address,
        FarmingABI,
        signer
      );

      const createdClaimAndStake = await contract.claimAndStake(poolIndex);
      return createdClaimAndStake;
    } catch (error) {
      this.handleWalletError(error);
      return error;
    }
  };

  /**
   * Generate transaction link to etherscan/bscscan for current network
   * @param {string} hash
   * @return {string} link
   */
  public getExplorerLink = (hash: string): string => {
    const { networkId } = this.network;

    if (networkId === BSC_MAINNET_NETWORK.networkId) {
      return `https://bscscan.com/tx/${hash}`;
    }

    if (networkId === BSC_TEST_NETWORK.networkId) {
      return `https://testnet.bscscan.com/tx/${hash}`;
    }

    return createExplorerLink(hash, networkId.toString());
  };

  calculateEarnings(value: string | number, operator: "+" | "-"): Earnings {
    const { amountOfBlockersPerYear } = this.network;
    const {
      amount,
      isZeroTotals,
      tokensFarmedPerBlock,
      allocPoint,
      totalAllocPoint,
      totalLiquidityDeposited,
      decimals,
      stake,
    } = this.pool;

    const inputValue = parseFixed(value.toString(), decimals);
    const amountValue =
      operator === "+" ? amount.add(inputValue) : amount.sub(inputValue);
    const amountAsBN = amountValue.lt(0) ? BigNumber.from(0) : amountValue;

    const perBlockEarnings: number = isZeroTotals
      ? 0
      : Number(
          formatFixed(
            tokensFarmedPerBlock
              .mul(allocPoint)
              .div(totalAllocPoint)
              .mul(amountAsBN)
              .div(totalLiquidityDeposited),
            decimals
          )
        );

    const dailyEarning = (perBlockEarnings * amountOfBlockersPerYear) / 365.25;
    const monthlyEarning = (perBlockEarnings * amountOfBlockersPerYear) / 12;
    const yearlyEarning = perBlockEarnings * amountOfBlockersPerYear;

    const operationResult =
      operator === "+"
        ? Number(stake) + Number(value)
        : Number(stake) - Number(value);
    const dailyROI = dailyEarning ? (dailyEarning / operationResult) * 100 : 0; // * 100%
    const monthlyROI = monthlyEarning
      ? (monthlyEarning / operationResult) * 100
      : 0; // * 100%
    const yearlyROI = yearlyEarning
      ? (yearlyEarning / operationResult) * 100
      : 0; // * 100%

    return {
      dailyEarning,
      monthlyEarning,
      yearlyEarning,
      dailyROI,
      monthlyROI,
      yearlyROI,
    };
  }
}

const ethereum = new Ethereum();

export default ethereum;
