import { Tabs } from "antd";

import { useWallet } from "hooks";

import { PoolsList } from "./components";

import styles from "./Farming.module.scss";

const { TabPane } = Tabs;

const Farming = () => {
  const { networkId, activeNetwork } = useWallet();
  const isAvailableNetwork = networkId === activeNetwork.networkId;

  return (
    <>
      <h1 className={styles.title}>NUX community staking program</h1>

      <Tabs defaultActiveKey="1" className={styles.tabs}>
        <TabPane tab="Live" key="1">
          {isAvailableNetwork && <PoolsList />}
        </TabPane>

        <TabPane tab="Ended" key="2" />
      </Tabs>
    </>
  );
};

export default Farming;
