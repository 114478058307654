import NumberFormat from "react-number-format";

type NumericTextProps = {
  value?: number | string | null;
  precision?: number;
  suffix?: string;
  prefix?: string;
  className?: string;
  fixedDecimalScale?: boolean;
};

const NumericText = ({
  suffix,
  precision = 2,
  value,
  prefix,
  className,
  fixedDecimalScale = false,
}: NumericTextProps) => {
  const suffixValue = suffix ? ` ${suffix}` : "";

  return (
    <NumberFormat
      value={value}
      displayType="text"
      prefix={prefix}
      suffix={suffixValue}
      decimalScale={precision}
      thousandSeparator
      className={className}
      fixedDecimalScale={fixedDecimalScale}
    />
  );
};

export default NumericText;
