import { useState } from "react";

import { Card } from "antd";

import { Pool } from "types";
import { SHOW_UNSTAKE_AND_EXIT_BUTTONS } from "config";
import { formatUTC } from "helpers/date";
import { convertToUSD } from "helpers/formatting";
import { useModal } from "hooks";
import { usePoolMutations } from "hooks/mutations";
import {
  NumericText,
  Button,
  Modal,
  DepositModal,
  WithdrawModal,
  LoadingModal,
} from "components";

import { DetailsText } from "./components";

import styles from "./PoolCard.module.scss";

type PoolCardProps = {
  pool: Pool;
};

type ModalType = "" | "deposit" | "withdraw";

const PoolCard = ({ pool }: PoolCardProps) => {
  const {
    symbol,
    totalStaked,
    farmingAPY,
    startDate,
    endDate,
    stake,
    reward,
    state,
    poolIndex,
    liquidityTokenAddress,
    tokenPrice,
    tokenSymbol,
  } = pool;

  const { isOpen, onOpen, onClose } = useModal();
  const { claim, exit, transactionLink, claimAndStake } = usePoolMutations(
    liquidityTokenAddress,
    poolIndex
  );
  const [modalType, setModalType] = useState<ModalType>("");

  const isDisabledStake = Number(stake) === 0;
  const isDisabledRewards = Number(reward) === 0;

  const handleModalOpen = (type: ModalType) => () => {
    setModalType(type);
    onOpen();
  };

  const handleClaimModalOpen = async () => {
    try {
      setModalType("");

      onOpen();
      await claim();
    } catch (error) {
      console.log(error);
    } finally {
      onClose();
    }
  };

  const handleExitModalOpen = async () => {
    try {
      setModalType("");

      onOpen();
      await exit();
    } catch (error) {
      console.log(error);
    } finally {
      onClose();
    }
  };

  const handleClaimAndStakeModalOpen = async () => {
    try {
      setModalType("");

      onOpen();
      await claimAndStake();
    } catch (error) {
      console.log(error);
    } finally {
      onClose();
    }
  };

  const renderModalByType = () => {
    switch (modalType) {
      case "deposit":
        return <DepositModal pool={pool} />;
      case "withdraw":
        return <WithdrawModal pool={pool} />;
      default:
        return (
          <LoadingModal onClose={onClose} transactionLink={transactionLink} />
        );
    }
  };

  return (
    <>
      <Card className={styles.card}>
        <header className={styles.cardHeader}>
          <div className={styles.titleWrapper}>
            <h5 className={styles.title}>
              Deposit {symbol} to earn {tokenSymbol}
            </h5>
            <span className={styles.status}>{state}</span>
          </div>

          <div className={styles.totalInfo}>
            <DetailsText label="TVL">
              <NumericText
                value={convertToUSD(totalStaked, tokenPrice)}
                prefix="$"
              />
            </DetailsText>
            <DetailsText label="APR">
              <NumericText value={farmingAPY} suffix="%" />
            </DetailsText>
          </div>
        </header>

        <div className={styles.line} />

        <div className={styles.cardBody}>
          <div className={styles.dates}>
            <DetailsText label="Start date">
              {startDate ? formatUTC(startDate, "DD MMM YYYY") : ""}
            </DetailsText>

            <DetailsText label="Next Quarter Rewards">
              {endDate ? formatUTC(endDate, "DD MMM YYYY") : ""}
            </DetailsText>

            <DetailsText label="End date">beyond 2023</DetailsText>
          </div>

          <div className={styles.totalStakeInfo}>
            <DetailsText label="Your stake">
              <NumericText value={convertToUSD(stake, tokenPrice)} prefix="$" />
            </DetailsText>

            <DetailsText label="Earned reward">
              <NumericText
                value={convertToUSD(reward, tokenPrice)}
                prefix="$"
              />
            </DetailsText>
          </div>
        </div>

        <footer className={styles.cardFooter}>
          <Button
            size="large"
            additionalText={symbol}
            onClick={handleModalOpen("deposit")}
          >
            Stake
          </Button>

          <Button
            size="large"
            additionalText={symbol}
            disabled={isDisabledRewards}
            onClick={handleClaimAndStakeModalOpen}
          >
            Claim and Stake
          </Button>

          <Button
            size="large"
            additionalText={`${tokenSymbol} reward`}
            disabled={isDisabledRewards}
            onClick={handleClaimModalOpen}
          >
            Claim
          </Button>

          {SHOW_UNSTAKE_AND_EXIT_BUTTONS && (
            <>
              <Button
                size="large"
                additionalText={`${symbol || ""} stake`}
                disabled={isDisabledStake}
                onClick={handleModalOpen("withdraw")}
              >
                Unstake
              </Button>

              <Button
                size="large"
                additionalText="Unstake and Claim"
                disabled={isDisabledStake}
                onClick={handleExitModalOpen}
              >
                Exit
              </Button>
            </>
          )}
        </footer>
      </Card>

      <Modal visible={isOpen} onCancel={onClose}>
        {renderModalByType()}
      </Modal>
    </>
  );
};

export default PoolCard;
