import React from "react";
import ReactDOM from "react-dom";

import "@fontsource/poppins";
import "@fontsource/poppins/600.css";
import "@fontsource/source-sans-pro";
import "@fontsource/source-sans-pro/600.css";

import "./index.scss";

import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
